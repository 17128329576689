import { Menu, MenuItem } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state"
import React from 'react'
import { ActOSRDialog } from '../../../acts/aosr/ActOSRDialog'
import { LightButton } from '../../../common/LightButton'

type Props = {
  buildingId: string,
  items: string[]
}
export const CreateActMenu = ({buildingId, items}: Props) => {


  if (items.length === 0){
    return <div onClick={() => alert("Выберите записи")}><LightButton disabled>Создать акт</LightButton></div>
  }

  return(
    <>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <>
            <LightButton {...bindTrigger(popupState)}>
              Создать акт
            </LightButton>
            <Menu {...bindMenu(popupState)}>
              <ActOSRDialog buildingId={buildingId} isCreate={true} renderButton={(onClick) => (
                <MenuItem onClick={onClick}>Акт ОСР</MenuItem>
              )} prevValues={{items: items}} onClose={popupState.close}/>

              <MenuItem onClick={popupState.close}>Акт ООК</MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
    </>
  )
}
