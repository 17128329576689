import { Button, styled } from '@mui/material'
import { darken, lighten } from '@mui/system'
import { LoadingButton } from '@mui/lab'

const LightButton = styled(Button)(({ theme, color}) => ({
  backgroundColor: (theme.palette.mode === 'light' ? lighten : darken)(
    (theme.palette[color === 'inherit' || !color ? 'primary' : color] as any).light,
    0.9
  ),
  padding: theme.spacing(1, 2),
}))

const LightLoadingButton = styled(LoadingButton)(({ theme, color }) => ({
  backgroundColor: (theme.palette.mode === 'light' ? lighten : darken)(
    (theme.palette[color === 'inherit' || !color ? 'primary' : color] as any).light,
    0.9
  ),
  padding: theme.spacing(1, 2),
}))

export { LightButton, LightLoadingButton }
