import { coerce, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Box, Link, Stack } from '@mui/material'
import { TBuilding } from '../../../types/building'
import { useGetCompaniesQuery } from '../../../store/companies'
import { employeesList } from '../../../util/employees'
import FormInput from '../../form/FormInput'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../../store/lists'
import { additionUnits } from '../../../util/additionUnits'
import AutocompleteInput from '../../form/AutocompleteInput'
import { companiesList } from '../../../util/companies'
import FormSelect from '../../form/FormSelect'
import { useGetEmployeesQuery } from '../../../store/employees'
import { FormInputDate } from '../../form'
import { FormSelectCompany } from '../../form/FormSelectCompany'

const schema = {
  signer_id: string().min(1, 'Необходимо заполнить'),
  supplier_id: string().min(1, 'Необходимо заполнить'),
  date: string().min(1, 'Необходимо заполнить дату'),
  data: object({
    material: string().min(1, 'Необходимо выбрать материал'),
    unit: string().min(1, 'Необходимо заполнить'),
    amount: coerce.number().positive('Значение должно быть больше 0'),
    quality_conclusion: string().min(1, 'Необходимо заполнить'),
  }),
}
const registerSchema = object(schema)

interface Props {
  building: TBuilding
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const ItemForm = ({ building, values, onSubmit, id }: Props) => {
  const {
    data: units,
    isSuccess: isUnitsSuccess,
    refetch: refetchUnits,
  } = useGetUnitsQuery(building.id)

  const {
    data: materials,
    isSuccess: isMaterialsSuccess,
    refetch: refetchMaterials,
  } = useGetMaterialsQuery(building.id)

  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const { handleSubmit, setValue, clearErrors } = methods

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }

  if (!isMaterialsSuccess || !isUnitsSuccess) {
    return <></>
  }

  const selectMaterialsItems = Object.values(materials).map((material) => material.name)
  const selectUnitsItems = additionUnits(Object.values(units).map((unit) => unit.name))

  const signerItems =
    companies && employees
      ? employeesList(companies, employees, ['building_contractor', 'contractor'], 'auth_itp')
      : []

  const supplierItems = companies ? companiesList(companies, ['supplier']) : []

  const handleQualityConclusion = () => {
    setValue('data.quality_conclusion', 'Дефекты не выявлены')
    clearErrors('data.quality_conclusion')
  }

  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <AutocompleteInput name="data.material" label="Материал" variants={selectMaterialsItems} />
        <Stack direction="row" spacing={1}>
          <FormInput name="data.amount" label="Объем" />
          <AutocompleteInput
            name="data.unit"
            label="Единица измерения"
            variants={selectUnitsItems}
          />
        </Stack>
        {/* <FormSelect name="supplier_id" label="Поставщик" items={supplierItems} /> */}
        <FormSelectCompany
          name="supplier_id"
          label="Поставщик"
          types={['supplier']} buildingId={building.id}
          onAdd={(id) => setValue('supplier_id', id)}
          filter={{role: ['supplier']}}
        />
        <FormSelect name="signer_id" label="Ответственный" items={signerItems} />
        <FormInputDate name="date" label="Дата поступления" />
        <FormInput
          name="data.quality_conclusion"
          label="Заключение о качестве"
          hint={
            <Link href="#" onClick={handleQualityConclusion}>
              Дефекты не выявлены
            </Link>
          }
        />
      </Box>
    </FormProvider>
  )
}

export default ItemForm
